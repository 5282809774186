
const THEME = {
  Default: 'default',
  Single: 'single',
}
export default {
  name: 'ProductsCard',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: THEME.Default,
    },
  },
  computed: {
    wrapperClass() {
      if (this.theme === THEME.Single) {
        return 'group flex flex-col md:flex-row items-center justify-between bg-white rounded-xl shadow cursor-pointer w-full overflow-hidden select-none max-w-xs md:max-w-full mx-auto md:p-12'
      }
      return 'group flex flex-col items-center justify-between bg-white rounded-xl shadow cursor-pointer w-full overflow-hidden select-none max-w-xs md:max-w-full mx-auto'
    },
    imageContainerClass() {
      if (this.theme === THEME.Single) {
        return 'mb-4 md:mb-0 w-full pb-2/3 md:w-1/2 md:pb-1/3 relative overflow-hidden'
      }
      return 'mb-4 md:mb-8 w-full pb-2/3 relative overflow-hidden'
    },
    descriptionClass() {
      if (this.theme === THEME.Single) {
        return 'pt-3 pb-5 md:pb-20 text-center text-gray-1 max-w-[360px] mx-auto'
      }
      return 'pt-3 pb-5 text-center text-gray-1'
    },
  },
}
