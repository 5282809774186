
export default {
  name: 'SectionTwoColumnsTelemedic',
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
}
